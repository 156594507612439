import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import AlertTitle from "@mui/material/AlertTitle";

import React, { FC } from "react";
import { NotificationTypes } from "./index";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

interface PropsInput {
  type: NotificationTypes;
  title?: string;
  message?: string;
  onClose: (event: any) => void;
  open: boolean;
  position?: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "top";
  };
}

const CoreNotification: FC<PropsInput> = ({
  title,
  message,
  type,
  onClose,
  open,
  position = {
    horizontal: "right",
    vertical: "bottom",
  },
}) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={position}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Box
        sx={{
          minWidth: "400px",
          borderTop: "2px solid transparent",
          borderBottom: "2px solid transparent",
          borderLeft: `4px solid`,
          borderLeftColor: `${type}.main`,
        }}
      >
        <Alert
          severity={type as AlertColor | undefined}
          style={{ borderRadius: 0, alignItems: "center" }}
          elevation={2}
          iconMapping={{
            error: <ErrorOutlineOutlinedIcon />,
            warning: <WarningAmberOutlinedIcon />,
            success: <CheckCircleOutlineOutlinedIcon />,
            info: <InfoOutlinedIcon />,
          }}
        >
          <AlertTitle style={{ fontWeight: "bold" }}>{title}</AlertTitle>
          {message}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default CoreNotification;
