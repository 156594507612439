// Library imports
import React, { useMemo } from "react";
import styled from "styled-components";

// Style imports
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

// Internal imports
import AppBar from "./components/main/AppBar";
import AppBody from "./components/main/AppBody";

// Global style
import "./App.css";
import { NotificationProvider } from "./components/core/useNotification";

const AppContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const App = () => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
          primary: {
            main: "#d7d8db",
            dark: "#3c3d47",
          },
          secondary: {
            main: "#515465",
          },
          background: {
            paper: "#454750",
            default: "#454750",
          },

          error: { main: "#CE381D", light: "#FFB3BA", dark: "#97000E" },
          warning: { main: "#EABE58" },
          info: { main: "#5886DC" },
          success: { main: "#8EC77C", light: "#c9e9c9", dark: "#4a824a" },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotificationProvider>
        <AppContainer>
          <AppBar />
          <AppBody />
        </AppContainer>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
