// Library imports
import React, { FC, useEffect, useMemo } from "react";

// Style imports
import { observer } from "mobx-react";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// Store imports
import { useStores } from "../../../stores/StoresProvider";
import Sidebar from "./sidebar";
import DeploymentCard from "../../core/DeploymentCard";
import { DRAWER_WIDTH } from "../../../configs/resources";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoDataFoundImage = require("./no-data-img.png") as unknown as string;

const BOX_SX = {
  flexGrow: 1,
  width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },

  display: "flex",
  flexWrap: "wrap",
};

const MainPage: FC = observer(() => {
  const { mainStore } = useStores();

  useEffect(() => {
    mainStore.fetchData();
  }, []);

  const dataList = mainStore.menuOption
    ? mainStore.data?.[mainStore.menuOption]
    : [];

  const refetchDataBtn = useMemo(
    () => (
      <Button
        disabled={mainStore.isDataLoading}
        onClick={() => mainStore.fetchData()}
      >
        Reload data
      </Button>
    ),
    [mainStore.isDataLoading]
  );

  if (mainStore.isDataLoading) {
    return <CircularProgress />;
  }

  // No data found
  if (!mainStore.data || Object.keys(mainStore.data)?.length === 0) {
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <img
          src={NoDataFoundImage}
          style={{ width: "200px", marginBottom: "20px" }}
        />

        <Typography gutterBottom variant="h4">
          No deployment received!
        </Typography>

        <Typography gutterBottom variant="subtitle2">
          Try reloading to bring the latest data...
        </Typography>

        {refetchDataBtn}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <Sidebar />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingTop: "88px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {mainStore.menuOption && (
            <Typography
              variant="h4"
              sx={{ marginLeft: "20px", marginRight: "20px" }}
            >
              {mainStore.menuOption}
            </Typography>
          )}

          {refetchDataBtn}
        </Box>

        <Box component="main" sx={BOX_SX}>
          {!dataList || dataList?.length === 0 ? (
            <Typography>No deployment found!</Typography>
          ) : (
            dataList.map((item, index) => (
              <DeploymentCard
                key={`${item.name}-deployment-${index}`}
                item={item}
              />
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
});

export default MainPage;
