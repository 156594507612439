// Library imports
import React, { FC } from "react";

// Style imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NotFoundPage: FC = () => {
  return (
    <Box>
      <Typography>404 Not fount</Typography>
    </Box>
  );
};

export default NotFoundPage;
