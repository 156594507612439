// Library imports
import * as React from "react";
import { observer } from "mobx-react";

// Style imports
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import { Typography } from "@mui/material";

// Store imports
import { useStores } from "../../../../stores/StoresProvider";

// Internal imports
import { DRAWER_WIDTH } from "../../../../configs/resources";

const Sidebar = observer(() => {
  const { mainStore } = useStores();
  const { menuOption, data, menuMobileOpen, setMenuMobileOpen } = mainStore;

  const handleDrawerToggle = () => {
    setMenuMobileOpen(!menuMobileOpen);
  };

  const drawer =
    data && Object.keys(data)?.length > 0 ? (
      <div>
        <Toolbar />
        <Divider />
        <List>
          {Object.keys(data).map((text, id) => (
            <ListItem
              onClick={() => mainStore.setMenuOption(text)}
              key={id}
              disablePadding
              style={
                menuOption === text
                  ? { backgroundColor: "rgba(0,0,0,0.2)" }
                  : {}
              }
            >
              <ListItemButton>
                <ListItemIcon>
                  <DeveloperBoardIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    ) : (
      <Typography>No data found</Typography>
    );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={menuMobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
});

export default Sidebar;
