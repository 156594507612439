// Library imports
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react";

// Store imports
import { useStores } from "../../stores/StoresProvider";

// Internal imports
import Router from "./router";
import { CircularProgress } from "@mui/material";

const AppBody = observer(() => {
  const { mainStore } = useStores();
  const {
    isLoading,
    isAuthenticated,
  }: {
    isLoading: boolean;
    isAuthenticated: boolean;
  } = mainStore;

  if (isLoading) return <CircularProgress />;

  return (
    <BrowserRouter>
      <Router isAuthenticated={isAuthenticated} />
    </BrowserRouter>
  );
});

export default AppBody;
