import React, { createContext, useContext, useState, FC } from "react";
import CoreNotification from "./CoreNotification";

interface ProviderProps {
  children: React.ReactNode;
}

interface NotificationContextProps {
  error: (message: string) => void;
  warning: (message: string) => void;
  info: (message: string) => void;
  success: (message: string) => void;
}

// Keep the functions declared as mandatory
export const NotificationContext = createContext<NotificationContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error: (message: string) => {
    /** */
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  warning: (message: string) => {
    /** */
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  info: (message: string) => {
    /** */
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  success: (message: string) => {
    /** */
  },
});

export enum NotificationTypes {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

export const NotificationProvider: FC<ProviderProps> = ({ children }) => {
  const [message, setMessage] = useState("");
  const [type, setType] = useState<NotificationTypes>(NotificationTypes.info);

  const setData = (
    newType: NotificationTypes | undefined,
    newMessage: string
  ) => {
    setMessage(newMessage);
    newType && setType(newType);
  };

  const error = (newMessage = "") => {
    setData(NotificationTypes.error, newMessage);
  };

  const warning = (newMessage = "") => {
    setData(NotificationTypes.warning, newMessage);
  };

  const info = (newMessage = "") => {
    setData(NotificationTypes.info, newMessage);
  };

  const success = (newMessage = "") => {
    setData(NotificationTypes.success, newMessage);
  };

  const onClose = () => {
    setMessage("");
  };

  return (
    <NotificationContext.Provider
      value={{
        error,
        warning,
        info,
        success,
      }}
    >
      {children}

      <CoreNotification
        open={!!message}
        type={type}
        message={message}
        onClose={onClose}
      />
    </NotificationContext.Provider>
  );
};

// Use created context along the app
export const useNotification = (): NotificationContextProps =>
  useContext(NotificationContext);
