import * as React from "react";
import styled from "styled-components";

import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import PlayArrowSharpIcon from "@mui/icons-material/PlayArrowSharp";
import StopSharpIcon from "@mui/icons-material/StopSharp";

import StatusChip from "./StatusChip";
import { DeploymentAPI } from "../../stores/MainStore";
import { useStores } from "../../stores/StoresProvider";
import { useNotification } from "./useNotification";

const StyledDeveloperBoardIcon = styled(DeveloperBoardIcon)`
  margin-right: 8px;
`;

const StyledCard = styled(Card)`
  max-width: 320px;
  margin: 20px;
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    border: 1px solid #1976d2;
  }
`;

const DeploymentCard: React.FC<{ item: DeploymentAPI }> = ({ item }) => {
  const notification = useNotification();
  const { mainStore } = useStores();
  const [isStarting, setIsStarting] = React.useState(false);
  const [isStopping, setIsStopping] = React.useState(false);
  const handleStart = () => {
    setIsStarting(true);

    mainStore
      .manipulateDeployment(item.id, mainStore.menuOption, true)
      .then(() => {
        setIsStarting(false);
        notification.info(`Starting machine -${item.name}- `);
      })
      .catch(() => {
        setIsStarting(false);
        notification.success(`Machine -${item.name}- could not be started!`);
      });
  };

  const handleStop = () => {
    setIsStopping(true);

    mainStore
      .manipulateDeployment(item.id, mainStore.menuOption)
      .then(() => {
        setIsStopping(false);
        notification.info(`Stopping machine -${item.name}- `);
      })
      .catch(() => {
        setIsStopping(false);
        notification.success(`Machine -${item.name}- could not be stopped!`);
      });
  };

  return (
    <StyledCard>
      <CardContent sx={{ minHeight: "100px" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <StyledDeveloperBoardIcon />
          <Typography variant="h5" component="div">
            {item.name || "--"}
          </Typography>

          <StatusChip status={item.status} />
        </Box>

        {/* <Typography variant="body2" color="text.secondary">{item.description}</Typography> */}
      </CardContent>

      <CardActions>
        <LoadingButton
          loading={isStarting}
          loadingPosition="start"
          startIcon={<PlayArrowSharpIcon />}
          variant="outlined"
          sx={{ width: "100%" }}
          disabled={item.status?.toLowerCase() !== "offline" || isStarting}
          onClick={handleStart}
        >
          Start
        </LoadingButton>

        <LoadingButton
          loading={isStopping}
          loadingPosition="start"
          startIcon={<StopSharpIcon />}
          variant="outlined"
          sx={{ width: "100%" }}
          disabled={item.status?.toLowerCase() !== "online" || isStopping}
          onClick={handleStop}
        >
          Stop
        </LoadingButton>
      </CardActions>
    </StyledCard>
  );
};

export default DeploymentCard;
