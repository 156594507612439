import { createContext, useContext } from "react";

import { MainStore } from "./MainStore";

export class RootStore {
  mainStore: MainStore;

  constructor() {
    this.mainStore = new MainStore();
  }
}

const StoresContext = createContext(new RootStore());

export const useStores = (): RootStore => useContext(StoresContext);
