// Library imports
import React, { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

// Style imports
import { default as AppBarComponent } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// Store imports
import { useStores } from "../../stores/StoresProvider";
import { Avatar, Button } from "@mui/material";

// Internal imports
import { DRAWER_WIDTH } from "../../configs/resources";

const ItemsContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

const StyledUsername = styled(Typography)`
  padding: 8px;
`;

const AppBar: FC = observer(() => {
  const { mainStore } = useStores();
  const {
    isAuthenticated,
    onLogout,
    menuMobileOpen,
    setMenuMobileOpen,
    profile,
  } = mainStore;

  const handleDrawerToggle = () => {
    setMenuMobileOpen(!menuMobileOpen);
  };

  if (!isAuthenticated) return null;

  return (
    <AppBarComponent position="fixed" style={{ zIndex: 5000 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <ItemsContainer>
          <AvatarContainer>
            <StyledUsername variant="body1">{profile?.login}</StyledUsername>
          </AvatarContainer>
          <Button
            variant="outlined"
            size="small"
            style={{ color: "#FFF", borderColor: "#FFF" }}
            onClick={onLogout}
          >
            Log out
          </Button>
        </ItemsContainer>
      </Toolbar>
    </AppBarComponent>
  );
});

export default AppBar;
