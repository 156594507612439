// Library imports
import { makeAutoObservable, runInAction } from "mobx";

export interface DeploymentAPI {
  name: string;
  id: string;
  status: string;
}
export interface DataAPI {
  [key: string]: DeploymentAPI[];
}

export interface UserProfile {
  access_token: string;
  name: string;
  login: string;
}

export class MainStore {
  profile?: UserProfile = undefined;
  data: DataAPI = {};
  isLoading = false;
  isDataLoading = true;
  menuMobileOpen = false;
  menuOption = "";

  constructor() {
    const profile = localStorage.getItem("profile");
    if (profile) {
      this.profile = JSON.parse(profile);
    } else {
      //localStorage.setItem("profile", JSON.stringify(this.profile));
    }

    makeAutoObservable(this);
  }

  get isAuthenticated() {
    return !!this.profile;
  }

  setProfile = (newProfile?: UserProfile) => {
    this.profile = newProfile;
    localStorage.setItem("profile", JSON.stringify(this.profile));
  };

  setLoading = (newLoading: boolean) => {
    this.isLoading = newLoading; // TODO:Check here
  };

  setMenuMobileOpen = (newMenuMobileOpen: boolean) => {
    this.menuMobileOpen = newMenuMobileOpen;
  };

  onLogout = () => {
    this.profile = undefined;
    localStorage.setItem("profile", JSON.stringify(this.profile));
  };

  // Sidebar
  setMenuOption = (menuOption: string) => {
    this.menuOption = menuOption;
  };

  // Main page
  fetchData = () => {
    this.isDataLoading = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const root = this;

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open("get", `${process.env.REACT_APP_ENGINE_URL}/servers/list`, true);

      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader(
        "Access-Control-Allow-Methods",
        "GET, POST, PATCH, PUT, DELETE, OPTIONS"
      );
      xhr.setRequestHeader(
        "Access-Control-Allow-Headers",
        "Origin, Content-Type, X-Auth-Token, Authorization, Lang"
      );

      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.profile?.access_token}`
      );

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          let responseData = {};
          try {
            responseData = JSON.parse(xhr.responseText || "{}");
            runInAction(() => {
              root.data = responseData;
              root.isDataLoading = false;
              root.menuOption = Object.keys(responseData)?.[0];
            });
          } catch {}

          if (xhr.status !== 200) {
            runInAction(() => {
              root.isDataLoading = false;
            });

            reject(new Error("API call failed"));
            return;
          }

          resolve(responseData);
        }
      };

      xhr.addEventListener("error", function (e) {
        runInAction(() => {
          root.isDataLoading = false;
        });
        reject(new Error("Operation is in error"));
      });
      xhr.addEventListener("abort", function (e) {
        runInAction(() => {
          root.isDataLoading = false;
        });
        reject(new Error("Operation was aborted"));
      });

      xhr.send();
    });
  };

  // Deployment cards
  manipulateDeployment = (
    deploymentId: string,
    category: string,
    start = false
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const root = this;
    const type = start ? "start" : "stop";

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      const formData = new FormData();
      formData.append("id", deploymentId);
      formData.append("category", category);

      const data = { category, id: deploymentId };

      xhr.open(
        "post",
        `${process.env.REACT_APP_ENGINE_URL}/servers/${type}`,
        true
      );

      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader(
        "Access-Control-Allow-Methods",
        "GET, POST, PATCH, PUT, DELETE, OPTIONS"
      );
      xhr.setRequestHeader(
        "Access-Control-Allow-Headers",
        "Origin, Content-Type, X-Auth-Token, Authorization, Lang"
      );

      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.profile?.access_token}`
      );

      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status !== 200) {
            runInAction(() => {
              root.isDataLoading = false;
            });
            reject(new Error("API call failed"));
            return;
          }

          runInAction(() => {
            const updatedList = root.data?.[root.menuOption]?.map((item) =>
              item.id === deploymentId ? { ...item, state: "Starting" } : item
            );
            root.data = {
              ...(root.data || {}),
              [root.menuOption]: updatedList,
            } as DataAPI;
          });
          resolve(true);
        }
      };

      xhr.addEventListener("error", function (e) {
        runInAction(() => {
          root.isDataLoading = false;
        });
        reject(new Error("Operation is in error"));
      });
      xhr.addEventListener("abort", function (e) {
        runInAction(() => {
          root.isDataLoading = false;
        });
        reject(new Error("Operation was aborted"));
      });

      xhr.send(JSON.stringify(data));
    });
  };
}
