// Library imports
import React, { FC, useCallback, useRef } from "react";
import {
  IResolveParams,
  LoginSocialGithub,
  TypeCrossFunction,
} from "reactjs-social-login";
import { GithubLoginButton } from "react-social-login-buttons";
import { Box, Typography, Grid } from "@mui/material";

import { useStores } from "../../../stores/StoresProvider";
import { loginBg } from "./loginBg";
import { UserProfile } from "../../../stores/MainStore";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Logo = require("./logo.png") as unknown as string;

const LoginPage: FC = () => {
  const { mainStore } = useStores();
  const githubRef = useRef<TypeCrossFunction>(null);
  const { setProfile, setLoading } = mainStore;

  const onLoginStart = useCallback(() => {
    setLoading(true);
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(undefined);
  }, []);

  const onResolve = ({ data }: IResolveParams) => {
    setProfile(data as UserProfile);
    setLoading(false);
  };

  const onReject = (err: any) => {
    console.log(err); // TODO:Show error
    setLoading(false);
  };

  // TODO: Fix blocked in loading...
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Grid container style={{ width: "100%", height: "100%" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "auto",
            height: "auto",
            backgroundImage: loginBg,
            backgroundSize: "cover",
            boxShadow: "0 0 15px rgba(0,0,0,0.65)",
            clipPath: "inset(0px -15px 0px 0px)",
          }}
        >
          <img src={Logo} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "auto",
            height: "auto",
          }}
        >
          <Box style={{ maxWidth: "400px", width: "100%" }}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              style={{
                marginTop: "30px",
                marginBottom: "30%",
                textAlign: "center",
              }}
            >
              Lan Servers Manager
            </Typography>

            <LoginSocialGithub
              ref={githubRef}
              client_id={process.env.REACT_APP_GITHUB_APP_ID || ""}
              client_secret={process.env.REACT_APP_GITHUB_APP_SECRET || ""}
              redirect_uri={process.env.REDIRECT_URI || ""}
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              onResolve={onResolve}
              onReject={onReject}
            >
              <GithubLoginButton />
            </LoginSocialGithub>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
