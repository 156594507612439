// Library imports
import React, { FC, Ref } from "react";
import { Route, Switch } from "react-router-dom";

// Internal imports
import { LoginPage, MainPage, NotFoundPage } from "../../pages";

// Configs
import { appRoutes, authRoutes } from "../../../configs/routes";
import { ROUTE_PAGES } from "../../../configs/resources";

interface Props {
  isAuthenticated: boolean;
}

const PAGES = {
  [ROUTE_PAGES.login]: LoginPage,
  [ROUTE_PAGES.main]: MainPage,
};

const Router: FC<Props> = ({ isAuthenticated }) => {
  const routes = isAuthenticated ? appRoutes : authRoutes;

  return (
    <Switch>
      {Object.keys(routes).map((routeKey: string) => {
        const Page = PAGES[routeKey];
        if (!Page) {
          return null;
        }

        return (
          <Route key={routeKey} exact path={routes[routeKey]}>
            <Page />
          </Route>
        );
      })}

      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default Router;
