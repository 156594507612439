import React, { FC } from "react";

import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

const STATUSES = {
  offline: "Offline",
  online: "Online",
};

const getChipProps = (status: any) => {
  if (status === STATUSES.online) {
    return {
      label: "Online",
      color: "success",
    };
  }
  if (status === STATUSES.offline) {
    return {
      label: "Offline",
      variant: "outlined",
    };
  }
  return {
    variant: "outlined",
    label: (
      <Box
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span>Loading</span>
        <CircularProgress size="20px" style={{ marginLeft: "10px" }} />
      </Box>
    ),
  };
};

interface Props {
  status: string;
}

const StatusChip: FC<Props> = (props) => {
  const { status } = props;

  const {
    label = "",
    variant = "filled",
    color = "default",
  } = getChipProps(status);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Chip
      sx={{ marginLeft: "auto" }}
      label={label}
      variant={variant}
      color={color}
    />
  );
};

export default StatusChip;
